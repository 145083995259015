import axios from "axios";
import { USER_DATA } from "../utils/CONSTANTS";
import { authStorage } from "../utils/login";
import { baseURL } from "../utils/urls";
import { Services } from "./service";

//****************Login************************

export const UserLogin = async (payload) => {
  let data = await Services.post("/auth/login", payload);
  return data;
};

export const SendCoupon = async (payload) => {
  let data = await Services.post("/coupons/reminder", payload);
  return data;
};

export const AddUser = async (payload) => {
  let data = await Services.post("/merchants", payload);
  return data;
};

// Reminder ****************************************
export const getReminder = async (mid) => {
  let data = await Services.get("/reminders/mid/" + mid);
  return data;
};

// ****************** Update Profile *****************
export const updateProfile = async (payload) => {
  let data = await Services.patch("/merchants", payload);
  return data;
};

export const updateLimit = async (payload) => {
  let data = await Services.post("/reminders", payload);
  return data;
};

//****************Gamification********************

export const getGamificationData = async (mid) => {
  let data = await Services.get("/gamifications/" + mid);
  return data;
};

export const updateGamificationData = async (payload) => {
  let data = await Services.post("/gamifications", payload);
  return data;
};

//****************Dashboard********************
export const GetDashboardData = async (startDate, endDate) => {
  let tempUrlgetTransactionAnalytics = "/transctions/analytics";
  let tempgetAllReturnCustomer = "/transctions/return-cust";

  if (authStorage.role !== "merchant") {
    tempUrlgetTransactionAnalytics += `/${authStorage.mid}/?startDate=`;
    tempgetAllReturnCustomer += `/${authStorage.mid}/?startDate=`;
  } else {
    tempUrlgetTransactionAnalytics += `/${USER_DATA.user.mid}/?startDate=`;
    tempgetAllReturnCustomer += `/${USER_DATA.user.mid}/?startDate=`;
  }

  const getAllTransaction = Services.post(
    baseURL +
      "/transctions/get?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&sortBy[transaction_total]=DESC&sortBy[discount_total]=DESC"
  );
  const getTransactionAnalytics = Services.get(
    baseURL + tempUrlgetTransactionAnalytics + startDate + "&endDate=" + endDate
  );

  const getAllReturnCustomer = Services.get(
    baseURL + tempgetAllReturnCustomer + startDate + "&endDate=" + endDate
  );
  return await axios
    .all([getAllTransaction, getTransactionAnalytics, getAllReturnCustomer])
    .then(
      axios.spread((...responses) => {
        return responses;
      })
    )
    .catch((errors) => {
      return -1;
    });
};

//******************* poster ******************

export const GetCategoryWisePoster = async (posterType) => {
  let data = await Services.get("/posters/type/" + posterType);
  return data;
};

export const GetMyProfile = async () => {
  // let data = await Services.get("/admin/getMe");
  // return data;
};

//******************* Transaction ******************
export const GetAllTransaction = async (startDate, endDate) => {
  // baseURL +
  // "/transctions/get?startDate=" +
  // startDate +
  // "&endDate=" +
  // endDate +
  // "&sortBy[transaction_total]=DESC&sortBy[discount_total]=DESC",

  let data = await Services.post(
    "/transctions/get?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&sortBy[transaction_total]=DESC&sortBy[discount_total]=DESC"
  );
  return data;
};

//******************* logo ******************
export const GetLogo = async () => {
  let data = await Services.get("/coupons/logos");
  return data;
};

export const UpDateLogo = async (body) => {
  let data = await Services.patch("/coupons/logos", body);
  return data;
};

export const sentCouponCode = async (body) => {
  let data = await Services.post("/coupons", body);
  return data;
};

//******************* Coupon Code ******************
export const UpDateExpiry = async (MID, body) => {
  let data = await Services.patch("/merchants/coupon-expiry/" + MID, body);
  return data;
};

export const GetExpiryDay = async (MID) => {
  let data = await Services.get("/merchants/coupon-expiry/" + MID);
  return data;
};
