export const baseURL = "https://prod-backend.givepay.net/api/v1";
// export const baseURL = "http://localhost:5050/api/v1";
export const ACCESS_TOKEN_KEY = "SAID";

export const CLOVER_END_POINT = "https://clover.com";
export const CLOVER_TOKEN_END_POINT = "https://api.clover.com/oauth/token";
export const CLOVER_APP_ID = "CYJN72XNH6TZC"
export const CLOVER_APP_SECRET = "36581528-6d39-cd3d-98eb-7d08f622f0c3"
export const PUBLIC_URL = "https://admin.givepay.net"
// export const PUBLIC_URL = "http://localhost:3000"
