import PageHeader from "../../../components/page-header";
import MobilePic from "../../../assets/img/mobile.png";
import { CircularProgress } from "@mui/material";
import { COLOR, CONSTANTS, USER_DATA } from "../../../utils/CONSTANTS";
import "./mobileCouponsLogo.css";
import { useContext, useEffect, useState } from "react";
import { GetLogo, sentCouponCode, UpDateLogo } from "../../../api/allServices";
import { Check } from "@material-ui/icons";
import { authStorage } from "../../../utils/login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { NavConstant } from "../../../store/context";

const MobileCouponsLogo = () => {
  const [logo, setLogo] = useState(null);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSending, setCodeSending] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [countryCode, setCountryCode] = useState(false);

  const Context = useContext(NavConstant);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await GetLogo(CONSTANTS.MID);
        if (CONSTANTS.RESPONSE_STATUS[res?.data?.status]) {
          // console.log(res?.data?.data?.data?.logoImage);
          setLogo(res?.data?.data?.data?.logoImage);
          setLoading(false);
        } else {
          console.log(res);
          setLoading(false);
        }
      } catch (error) {
        setLogo(null);
        setLoading(false);
      }
    })();
  }, [update, authStorage?.mid]);


  const updateLogoHandler = (e) => {

    (async () => {
      try {
        setLoading(true);


        const payload = new FormData();
        payload.append("mid", CONSTANTS.MID);
        payload.append("logoImage", e.target.files[0]);

        const res = await UpDateLogo(payload);

        setUpdate((previous) => !previous);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  };

  const sendCouponCodeHandler = () => {
    (async () => {
      try {
        setCodeSending(true);

        const payload = {
          transaction_amount: 10,
          mobile: countryCode + mobileNumber,
          type: 1,
          ...(authStorage.role === "merchant" && { mid: USER_DATA.user.mid })
        };
        const res = await sentCouponCode(payload);
        // console.log("res: ", res.data)
        if (CONSTANTS.RESPONSE_STATUS[res?.data?.status]) {
          setIsCodeSent(true);
          setMobileNumber("");
          countryCode.reset();
        } else {
          setIsCodeSent(false);
        }
        setCodeSending(false);
      } catch (error) {
        // console.log("error: ", error.response.data.message)

        Context.setIsErrorNotification({
          type: false,
          message: error?.response?.data?.message,
        });
        setCodeSending(false);
      }
    })();
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination="Logo" heading={CONSTANTS.MOBILE_COUPONS_TITLE} />
      <div className="container-fluid py-4">
        <div className="row content-wrapper ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h3 className="fst-italic">
              {CONSTANTS.MOBILE_COUPONS_UPLOAD_LOGO_TEXT}
            </h3>
            <div className="setup-form mt-3">
              <p>{CONSTANTS.MOBILE_COUPONS_PROMOTION_LINE}</p>
              <form className="small-form mt-3 mb-5">
                <p>{CONSTANTS.UPLOAD_LOGO_TEXT}</p>
                <div
                  className="file upload-btn-wrapper mt-5 mb-2"
                  style={{ display: "inline-block", cursor: "pointer" }}
                >
                  <button
                    className="btn"
                    style={{
                      cursor: "pointer",
                      background: "#1D94E6",
                      color: "#fff",
                    }}
                  >
                    {CONSTANTS.UPLOAD_LOGO}
                  </button>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="file"
                    style={{ cursor: "pointer" }}
                    onChange={updateLogoHandler}
                  />
                </div>
                <div className="form-group mb-2">
                  <p className="logo-label">
                    {CONSTANTS.SEND_COUPON_LABEL_TEXT}
                    {"   "}
                    {!codeSending && isCodeSent && (
                      <Check style={{ color: "green" }} />
                    )}
                    {codeSending ? "Sending Code...." : ""}
                  </p>
                </div>
                <div class="form-row">
                  <div
                    class="form-group col-lg-8 col-md-12 col-sm-12"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="input-group"
                      style={{ display: "contents" }}
                    >
                      <PhoneInput
                        country={"us"}
                        onChange={(phone) => setCountryCode(phone)}
                        inputClass="countryCode"
                      />

                      <div class="input-group-append">
                        <button
                          class="btn btn-outline"
                          style={{
                            backgroundColor: COLOR.THEME.secondory,
                            width: "175px",
                            borderBottom: "1px solid #707070",
                            borderTop: "1px solid #707070",
                            borderRight: "1px solid #707070",
                            borderTopRightRadius: "7px",
                            borderBottomRightRadius: "7px",
                          }}
                          type="button"
                          onClick={sendCouponCodeHandler}
                        >
                          {CONSTANTS.SEND_COUPON_BUTTON_TEXT}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <p className="small-lable mb-3">
                {`${CONSTANTS.SEND_COUPON_WARNING_LINE_1}${authStorage.role === "merchant"
                    ? USER_DATA.user?.firstname + " " + USER_DATA.user?.lastname
                    : ""
                  }${CONSTANTS.SEND_COUPON_WARNING_LINE_2} `}
              </p>
              {/* <p className="note-text">{CONSTANTS.SEND_COUPON_NOTE}</p> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
            {logo && (
              <img
                src={logo}
                alt="logo"
                className="img-fluid mobile-img-logo"
              />
            )}
            {loading && (
              <div className="img-fluid mobile-img-loader mobile-img-logo">
                <CircularProgress />
              </div>
            )}
            <img src={MobilePic} alt="" className="img-fluid mobile-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileCouponsLogo;
